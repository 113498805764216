
.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.quoteContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 20vw;

    padding: 2vw 0vw 2vw 0vw;

    color: aliceblue;
    background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url("../../assets/images/bg.jpg");


}

.quote {
    animation: resize-quoteContainer auto linear;
    animation-timeline: scroll();
}

.contentContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100%;
    padding: 2vw 0vw 2vw 0vw;

    text-align: center;

    color: azure;
    background-color: #1a1a1a;
}

.contentContainer .content {
    animation: fade-in-bottom auto linear;
    animation-timeline: scroll();
}

.content {
    margin: 1vw;

    animation: fade-in-bottom auto linear;
    animation-timeline: scroll();
}

.contentContainer h1 {
    margin: 0;
}

.horizontalAlign {
    height: 100%;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: left;
}

.horizontalAlign p {
    padding: 2vw;
}

@keyframes fade-in-bottom {
    0% {
              transform: translateY(50px)!important;
      opacity: 0!important;
    }
    100% {
              transform: translateY(0)!important;
      opacity: 1!important;
    }
  }

@keyframes resize-quoteContainer {
    0% {
        transform: scale(100%);
    }
    100% {
        transform: scale(50%);
    }
}
